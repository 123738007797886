import axios from 'axios';
import { configConstants } from '../constants';
import { authHeader } from '../helpers/auth-header';

export const marketplaceService = {
    addmarketplacesetting,
    getmarketplacesetting,
    updatemarketplacesetting
};

// Send Post Request To Add Listner Setting with Parameters and Auth Header
function addmarketplacesetting(val)
{
    //var isEditData = val.keyId,
    var defaultquentity=val.defaultquentity;
    var solditem=val.solditem;
    var repriceincreased=val.repriceincreased;
    var repricedecresed=val.repricedecresed;
    var roundcents=val.roundcents;
    var oositems=val.oositems;
    var autoebaystock=val.autoebaystock;
    var backoosstock=val.backoosstock;
    var fixeddurationrelease=val.fixeddurationrelease;
    var autoebayprice=val.autoebayprice;
    var newproductsg=val.newproductsg;
    var syncsales=val.syncsales;
    var channelsyncfrom=val.channelsyncfrom;
    var supplierincreasesprice=val.supplierincreasesprice;

    return axios
        .post(configConstants.API_URL+configConstants.API_ADDMARKETPLACESETTING, {
            defaultquentity:defaultquentity,
            solditem:solditem,
            repriceincreased:repriceincreased,
            repricedecresed:repricedecresed,
            roundcents:roundcents,
            oositems:oositems,
            autoebaystock:autoebaystock,
            backoosstock:backoosstock,
            fixeddurationrelease:fixeddurationrelease,
            autoebayprice:autoebayprice,
            newproductsg:newproductsg,
            syncsales:syncsales,
            channelsyncfrom:channelsyncfrom,
            supplierincreasesprice:supplierincreasesprice
        },{
            headers:authHeader()
        })
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Post Request To Get Listner Setting with Auth Header
function getmarketplacesetting()
{
    return axios
        .post(configConstants.API_URL+configConstants.API_GETMARKETPLACESETTING, {},{headers:authHeader()})
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Post Request To Update Listner Setting with Parameters and Auth Header
function updatemarketplacesetting(val)
{
    var isEditData = val.isEditData;
    var defaultquentity=val.defaultquentity;
    var solditem=val.solditem;
    var repriceincreased=val.repriceincreased;
    var repricedecresed=val.repricedecresed;
    var roundcents=val.roundcents;
    var oositems=val.oositems;
    var autoebaystock=val.autoebaystock;
    var backoosstock=val.backoosstock;
    var fixeddurationrelease=val.fixeddurationrelease;
    var autoebayprice=val.autoebayprice;
    var newproductsg=val.newproductsg;
    var syncsales=val.syncsales;
    var channelsyncfrom=val.channelsyncfrom;
    var supplierincreasesprice=val.supplierincreasesprice;

    return axios
        .post(configConstants.API_URL+configConstants.API_UPDATEMARKETPLACESETTING, {
            defaultquentity:defaultquentity,
            solditem:solditem,
            repriceincreased:repriceincreased,
            repricedecresed:repricedecresed,
            roundcents:roundcents,
            oositems:oositems,
            autoebaystock:autoebaystock,
            backoosstock:backoosstock,
            fixeddurationrelease:fixeddurationrelease,
            autoebayprice:autoebayprice,
            newproductsg:newproductsg,
            syncsales:syncsales,
            channelsyncfrom:channelsyncfrom,
            supplierincreasesprice:supplierincreasesprice,
            isEditData:isEditData
        },{
            headers:authHeader()
        })
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}