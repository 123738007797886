import React from 'react';
import { connect } from 'react-redux';
import Header from '../Header/header';
import Sidebar from '../Sidebar/sidebar';
//import { history } from '../../helpers/history';
//import { configConstants } from '../../constants';
import { insatntlogout } from '../../helpers/insatntlogout';
import { listerActions } from '../../actions/lister.actions';
import Apiloader from '../Apiloader/Apiloader';

class Listingsettings extends React.Component {

	constructor(props) {
        super(props);
        insatntlogout.logoutInsatnt();

    	this.state = {
    		pageTitle:"Listing Settings - Stock Arbiship",
    		paypalEmail:"",
    		titleKeyword:"",
    		locationZip:"",
    		locationDisplay:"",
    		shipFrom:"",
    		maxPrice:"",
    		BrandOverride:"",
    		isEditData:"",
			errors:{},
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.addSetting = this.addSetting.bind(this);
        this.updateSetting = this.updateSetting.bind(this);
    }

    componentDidMount(){
    	document.title = this.state.pageTitle
    	this.props.getlistersetting();
    }

    handleChange(dataType, value) {
		if (dataType === "paypalEmail") {
			this.setState({ paypalEmail: value });
		}
		if (dataType === "titleKeyword") {
			this.setState({ titleKeyword: value });
		}
		if (dataType === "locationZip") {
			this.setState({ locationZip: value });
		}
		if (dataType === "locationDisplay") {
			this.setState({ locationDisplay: value });
		}
		if (dataType === "shipFrom") {
			this.setState({ shipFrom: value });
		}
		if (dataType === "maxPrice") {
			this.setState({ maxPrice: value });
		}
		if (dataType === "BrandOverride") {
			this.setState({ BrandOverride: value });
		}
	}

    handleValidation(){
        let paypalEmail = this.state.paypalEmail;
        let titleKeyword = this.state.titleKeyword;
        let locationZip = this.state.locationZip;
        let locationDisplay = this.state.locationDisplay;
        let shipFrom = this.state.shipFrom;
        let maxPrice = this.state.maxPrice;
        let BrandOverride = this.state.BrandOverride;

        let errors = {};
        let formIsValid = true;

        //paypalEmail
        if(!paypalEmail || !paypalEmail.trim()){
           formIsValid = false;
           errors["paypalEmail"] = "This field is required";
        }

        //titleKeyword
        if(!titleKeyword || !titleKeyword.trim()){
           formIsValid = false;
           errors["titleKeyword"] = "This field is required";
        }

        //locationZip
        if(!locationZip || !locationZip.trim()){
           formIsValid = false;
           errors["locationZip"] = "This field is required";
        }

        //locationDisplay
        if(!locationDisplay || !locationDisplay.trim()){
           formIsValid = false;
           errors["locationDisplay"] = "This field is required";
        }

        //shipFrom
        if(!shipFrom || !shipFrom.trim()){
           formIsValid = false;
           errors["shipFrom"] = "This field is required";
        }

        //maxPrice
        if(!maxPrice || !maxPrice.trim()){
           formIsValid = false;
           errors["maxPrice"] = "This field is required";
        }

        //BrandOverride
        if(!BrandOverride || !BrandOverride.trim()){
           formIsValid = false;
           errors["BrandOverride"] = "This field is required";
        }

       	this.setState({errors: errors});
       	return formIsValid;
   	}

	handleClick(e) {
        this.props.getlistersetting();
    }

    addSetting(e){
        e.preventDefault();
        if(this.handleValidation()){
           	this.props.addlistersetting(this.state);
        }
    }

    updateSetting(e)
    {
    	e.preventDefault();
        if(this.handleValidation()){
           	this.props.updatelistersetting(this.state);
        }
    }

    componentWillReceiveProps(nextProps){
    	if (nextProps.listersetting.listersetting.length > 0) {
    		var count = nextProps.listersetting.listersetting;
    		count.map((val, index) => {
			    this.setState({
		        	paypalEmail:val.paypalEmail,
		        	titleKeyword : val.titleKeyword,
				    locationZip : val.locationZip,
				    locationDisplay : val.locationDisplay,
				    shipFrom : val.shipFrom,
				    maxPrice : val.maxPrice,
				    BrandOverride : val.BrandOverride,
				    isEditData : val.keyId,
		    	})
		    	return true;
			});
		}
  	}

    render() {
    	const { listersetting } = this.props;
    	var { paypalEmail,titleKeyword,locationZip,locationDisplay,shipFrom,maxPrice,BrandOverride } = this.state; //,isEditData

    	var subButton = "";
    	var frm = "";
    	if (listersetting.listersetting.length > 0) {
			/*var count = listersetting.listersetting;
			count.map((val, index) => {
			    paypalEmail = val.paypalEmail;
			    titleKeyword = val.titleKeyword;
			    locationZip = val.locationZip;
			    locationDisplay = val.locationDisplay;
			    shipFrom = val.shipFrom;
			    maxPrice = val.maxPrice;
			    BrandOverride = val.BrandOverride;
			    isEditData = val.keyId;
			});*/
    		subButton = <input type="submit" disabled={listersetting.issubmit} className="col-md-3 btn btn-primary" name="submit" value="Submit"/>;
    		frm = this.updateSetting;
    	}
    	else
    	{
    		subButton = <input type="submit" disabled={listersetting.issubmit} className="col-md-3 btn btn-primary" name="submit" value="Submit"/>;
    		frm = this.addSetting
    	}

        return (
        	<div id="page-wrapper">
	    		<Header/>
	    		<Sidebar/>
	    		<div id="page-content-wrapper">
	            	<div id="page-content">
	                    <div className="container">
	                    	<div id="page-title">
						    	<h2>Listing Settings</h2>
						    </div>
						    <div className="row mrg20B">
						    	<div className="panel">
						    		<div className="panel-body">
										<div className="row">
								            <div className="col-md-12">
								            	<label className="title-hero">
								            		General Listing Settings
								            	</label>
								            </div>
							            </div>
							            <form id="listersettings" name="listersettings" onSubmit={frm}>
								            <div className="row">
									            <div className="col-md-12">
									            	<div className="form-group">
									            		<label className="control-label">eBay Default PayPal Email</label>
									            		<input type="email" 
									            			className="form-control" 
									            			name="paypalEmail" 
							                    			id="paypalEmail" 
							                    			onChange={(e) => this.handleChange('paypalEmail', e.target.value)} 
							                    			value={paypalEmail}
								            			/>
									            		<span>if you change this, make sure to update your business policy profiles as well.</span>
									            		<span style={{color: "red"}}>{this.state.errors["paypalEmail"]}</span>
								            		</div>
									            </div>
								            </div>
								            <div className="row">
									            <div className="col-md-12">
									            	<div className="form-group">
									            		<label className="control-label">Additional title keywords</label>
									            		<input type="text" 
									            			className="form-control" 
									            			name="titleKeyword" 
							                    			id="titleKeyword" 
							                    			onChange={(e) => this.handleChange('titleKeyword', e.target.value)}
							                    			value={titleKeyword}
								            			/>
								            			<span style={{color: "red"}}>{this.state.errors["titleKeyword"]}</span>
								            		</div>
									            </div>
								            </div>
								            <div className="row">
									            <div className="col-md-5">
									            	<div className="form-group">
									            		<label className="control-label">Item location zip code for calculated shipping</label> <i className="glyph-icon icon-question-circle"></i>
									            		<input type="text" 
									            			className="form-control" 
									            			name="locationZip" 
							                    			id="locationZip" 
							                    			onChange={(e) => this.handleChange('locationZip', e.target.value)} 
							                    			value={locationZip}
									            		/>
									            		<span style={{color: "red"}}>{this.state.errors["locationZip"]}</span>
								            		</div>
									            </div>
								            </div>
								            <div className="row">
									            <div className="col-md-4">
									            	<div className="form-group">
									            		<label className="control-label">Item location for display to eBay buyer</label> <i className="glyph-icon icon-question-circle"></i>
									            		<input type="text" 
									            			className="form-control" 
									            			name="locationDisplay" 
							                    			id="locationDisplay" 
							                    			onChange={(e) => this.handleChange('locationDisplay', e.target.value)}
							                    			value={locationDisplay}
								            			/>
								            			<span style={{color: "red"}}>{this.state.errors["locationDisplay"]}</span>
								            		</div>
									            </div>
								            </div>
								            <div className="row">
									            <div className="col-md-6">
									            	<div className="form-group">
									            		<label className="control-label">Item ships-from country for display to eBay buyer (2-letter code)</label> <i className="glyph-icon icon-question-circle"></i>
									            		<input type="text" 
									            			className="form-control" 
									            			name="shipFrom" 
							                    			id="shipFrom" 
							                    			onChange={(e) => this.handleChange('shipFrom', e.target.value)} 
							                    			value={shipFrom}
								            			/>
								            			<span style={{color: "red"}}>{this.state.errors["shipFrom"]}</span>
								            		</div>
									            </div>
								            </div>
								            <div className="row">
									            <div className="col-md-6">
									            	<div className="form-group">
									            		<label className="control-label">Maximum source price for new listings</label> <i className="glyph-icon icon-question-circle"></i>
									            		<div className="input-group">
								                            <span className="input-group-addon">$</span>
								                            <input type="text" 
								                            	className="form-control"
								                            	name="maxPrice" 
								                    			id="maxPrice" 
								                    			onChange={(e) => this.handleChange('maxPrice', e.target.value)}
								                    			value={maxPrice}
							                            	/>
								                        </div>
								                        <span style={{color: "red"}}>{this.state.errors["maxPrice"]}</span>
								            		</div>
									            </div>
								            </div>
								            <div className="row">
									            <div className="col-md-3">
									            	<div className="form-group">
									            		<label className="control-label">Brand override</label> <i className="glyph-icon icon-question-circle"></i>
									            		<input type="text" 
									            			className="form-control" 
									            			name="BrandOverride" 
							                    			id="BrandOverride" 
							                    			onChange={(e) => this.handleChange('BrandOverride', e.target.value)}
							                    			value={BrandOverride}
								            			/>
								            			<span style={{color: "red"}}>{this.state.errors["BrandOverride"]}</span>
								            		</div>
									            </div>
								            </div>
								            <div className="row">
									            <div className="col-md-12">
									            	<div className="form-group">
									            		{subButton}
									            		{listersetting.loading ? (
															<Apiloader/>
														) : (
															""
														)}
								            		</div>
									            </div>
								            </div>
							            </form>						    			
						            </div>
					            </div>
					        </div>
	                    </div>
	                </div>
	            </div>
            </div>
        );
    }
}

//export default Listingsettings;

function mapState(state) {
	const listersetting = state.listersetting;
	return { listersetting };
}

const actionCreators = {
    addlistersetting: listerActions.addlistersetting,
    getlistersetting: listerActions.getlistersetting,
    updatelistersetting: listerActions.updatelistersetting
};

export default connect(mapState, actionCreators)(Listingsettings);