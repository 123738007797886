export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: "ALERT_SUCCESS", message:message };
}

function error(message) {
    return { type: "ALERT_ERROR", message:message };
}

function clear() {
    return { type: "ALERT_CLEAR" };
}