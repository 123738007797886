import { userService } from '../services/user.service';
import { history } from '../helpers/history';
import { alertActions } from './alert.actions';

import { userConstants } from '../constants/user.constants';
import { configConstants } from '../constants/config.constants';

export const userActions = {
    login,
    logout,
    checkalreadylogin
};

// Dispach Login Action  
function login(email, password) {
    return dispatch => {
    	dispatch(request({ email }));
        var ACCESS_TOKEN = getCook("ACCESS-TOKEN");
        userService.login(email, password, ACCESS_TOKEN)
            .then(
                user => { 
                	if (user) {
                        if (user.status === "200") {
                            dispatch(success(user.data));

                            localStorage.setItem('user', JSON.stringify(user.data));
                            localStorage.setItem('isValid', JSON.stringify(user.data.isValid));
                            setCookie("ACCESS-TOKEN",user.data.isValid,"1");

                            history.push('/dashboard');
                            dispatch(alertActions.success(user.message));
                        }
                        else
                        {
                            dispatch(failure(user.data));
                            history.push('/');
                            dispatch(alertActions.error(user.message));
                        }
                	}
                	else
                	{
                		dispatch(failure(user.data));
	                    history.push('/');
	                    dispatch(alertActions.error(user.message));
                	}
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }

}

// Dispach Logout Action
function logout() {
    return dispatch => {
        dispatch(request({  }));
        userService.logout()
            .then(
                user => { 
                    if (user) {
                        if (user.status === "200") {
                            dispatch(success(user));
                            // Remove Local storage
                            localStorage.removeItem('user');
                            localStorage.removeItem('isValid');
                            // Remove Local Cookie
                            eraseCookie("ACCESS-TOKEN");
                            history.push('/');
                            dispatch(alertActions.success(user.message));
                        }
                        else
                        {
                            dispatch(failure(user));
                            // Remove Local storage
                            localStorage.removeItem('user');
                            localStorage.removeItem('isValid');
                            // Remove Local Cookie
                            eraseCookie("ACCESS-TOKEN");
                            history.push('/');
                            dispatch(alertActions.success(user.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(user));
                        // Remove Local storage
                        localStorage.removeItem('user');
                        localStorage.removeItem('isValid');
                        // Remove Local Cookie
                        eraseCookie("ACCESS-TOKEN");
                        history.push('/');
                        dispatch(alertActions.error(user.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    // Remove Local storage
                    localStorage.removeItem('user');
                    localStorage.removeItem('isValid');
                    // Remove Local Cookie
                    eraseCookie("ACCESS-TOKEN");
                    history.push('/');
                    dispatch(alertActions.error("Error"));
                }
            );
    };

    function request() { return { type: userConstants.LOGOUT } }
    function success(user) { return { type: userConstants.LOGOUT } }
    function failure(error) { return { type: userConstants.LOGOUT } }
}

// Dispach Already Login or Not Action
function checkalreadylogin(cookieValue) {
    return dispatch => {
        dispatch(request({ cookieValue }));
        userService.checkalreadylogin(cookieValue)
            .then(
                user => { 
                    if (user) {
                        if (user.status === "200") {
                            dispatch(success(user.data));

                            localStorage.setItem('user', JSON.stringify(user.data));
                            localStorage.setItem('isValid', JSON.stringify(user.data.isValid));
                            history.push('/dashboard');

                            dispatch(alertActions.success(user.message));
                        }
                        else
                        {
                            dispatch(failure(user.data));
                            // Remove Local Cookie
                            eraseCookie("ACCESS-TOKEN");
                            // Remove Local storage
                            localStorage.removeItem('user');
                            localStorage.removeItem('isValid');
                            history.push('/');
                            dispatch(alertActions.error(user.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(user.data));
                        // Remove Local Cookie
                        eraseCookie("ACCESS-TOKEN");
                        // Remove Local storage
                        localStorage.removeItem('user');
                        localStorage.removeItem('isValid');
                        history.push('/');
                        dispatch(alertActions.error(user.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('/');
                    dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

// Set cookie in browser
function setCookie(name,value,days) {
    document.cookie = name + "=" + (value || "")  + "; Domain="+configConstants.COOKIE_DOMAIN+" ; path=/";
}

// Erase cookie from browser
function eraseCookie(name) {   
    document.cookie = name +'=; Domain='+configConstants.COOKIE_DOMAIN+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// Get cookie in browser
function getCook(cookiename) 
{
    var cookiestring=RegExp(cookiename+"=[^;]+").exec(document.cookie);
    return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
}