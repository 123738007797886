export const configConstants = {
	/* ------------- LOCAL SERVER ------------- */
    // APP_URL: 'http://localhost:3000/',
    // API_URL: 'http://localhost/arbiship/public/api/',
    // COOKIE_DOMAIN : "localhost",

    /* ------------- DEV ARBISHIP SERVER ------------- */
    APP_URL: 'https://devstock.arbiship.com/',
    API_URL: 'https://dev.arbiship.com/api/',
    COOKIE_DOMAIN : ".arbiship.com",

    // Common Use
    REGISTRATION_URL : "https://dev.arbiship.com/register",
    FORGOT_PWD_URL : "https://dev.arbiship.com/password/reset",

    // API Methods
    API_LOGIN: "userLogin",
    API_LOGOUT: "userLogout",
    API_USERDATA: "userData",
    API_ADDLISTER: "addLister",
    API_GETLISTER: "getLister",
    API_GETINVENTORY: "getInventory",
    API_UPDATEINVENTORY: "updateInventory",
    API_ADDLISTERSETTING: "addListerSetting",
    API_GETLISTERSETTING: "getListerSetting",
    API_UPDATELISTERSETTING: "updateListerSetting",
    API_ADDMARKETPLACESETTING: "addMarketplaceSetting",
    API_GETMARKETPLACESETTING: "getMarketplaceSetting",
    API_UPDATEMARKETPLACESETTING: "updateMarketplaceSetting",

    // AUTH
    AUTHTOKEN : "Bearer ",
};