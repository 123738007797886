import { listerConstants } from '../constants';

const initialState = {
  loading: false,
  issubmit:false,
  listersetting: []
}

export function listersetting(state = initialState, action) {
  switch (action.type) {
    case listerConstants.ADD_LST_SAVE_REQUEST:
      return {
        loading: true,
        issubmit:true,
        listersetting: action.listersetting
      };
    case listerConstants.ADD_LST_SAVE_SUCCESS:
      return {
        loading: false,
        issubmit:false,
        listersetting: action.listersetting
      };
    case listerConstants.ADD_LST__SAVE_FAILURE:
      return { 
        loading: false,
        issubmit:false,
        listersetting: action.listersetting
      };
    case listerConstants.GET_LST_SAVE_REQUEST:
      return {
        loading: true,
        issubmit:true,
        listersetting: action.listersetting,
      };
    case listerConstants.GET_LST_SAVE_SUCCESS:
      return {
        loading: false,
        issubmit:false,
        listersetting: action.listersetting
      };
    case listerConstants.GET_LST_SAVE_FAILURE:
      return { 
        loading: false,
        issubmit:false,
        listersetting: action.listersetting
      };
    case listerConstants.UPDATE_LST_SAVE_REQUEST:
      return {
        loading: true,
        issubmit:true,
        listersetting: action.listersetting,
      };
    case listerConstants.UPDATE_LST_SAVE_SUCCESS:
      return {
        loading: false,
        issubmit:false,
        listersetting: action.listersetting
      };
    case listerConstants.UPDATE_LST_SAVE_FAILURE:
      return { 
        loading: false,
        issubmit:false,
        listersetting: action.listersetting
      };
    default:
      return state
  }
}