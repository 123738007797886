import React from 'react';
import { connect } from 'react-redux';
import Header from '../Header/header';
import Sidebar from '../Sidebar/sidebar';
import { ebayActions } from '../../actions/ebay.actions';
import { insatntlogout } from '../../helpers/insatntlogout';

class Ebayusa extends React.Component {

	constructor(props) {
        super(props);
        insatntlogout.logoutInsatnt();

    	this.state = {
    			pageTitle:"eBay USA (US) - Stock Arbiship",
    			apicallloader: false
    		};
    	this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount(){
    	document.title = this.state.pageTitle
    }

	handleClick(e) {
        e.preventDefault();
        this.setState({ apicallloader: true });
        this.props.saveebay();

        setTimeout(() => {
	        this.setState({ apicallloader: false });
      	}, 1500);
    }

    render() {
	    const { apicallloader } = this.state;
        return (
        	<div id="page-wrapper">
        		{apicallloader ?
	        		<div id="loading">
				        <div className="spinner">
				            <div className="bounce1"></div>
				            <div className="bounce2"></div>
				            <div className="bounce3"></div>
				        </div>
				    </div>
				    :
				    <div id="loading" style={{display:'none'}}>
				        <div className="spinner">
				            <div className="bounce1"></div>
				            <div className="bounce2"></div>
				            <div className="bounce3"></div>
				        </div>
				    </div>
				}
	    		<Header/>
	    		<Sidebar/>
	    		<div id="page-content-wrapper">
	            	<div id="page-content">
	                    <div className="container">
	                    	<div id="page-title">
						    	<h2>Ebay USA (US)</h2>
						    </div>
						    <div className="row mrg20B">
						    	<div className="panel">
						    		<div className="panel-body">
									    <div className="row mrg20B">
								            <div className="col-md-12 text-center">
								                <h5>Ebay USA (US)</h5>
								            </div>
								            <div className="col-md-12 text-center">
								               {/*<b> <span style={colorgreen}>Not Sure Which Method of eBay Repricing You Want to Use?</span> <a href="#/" target="_blank">CLICK HERE</a> <span style={colorgreen}> to Explore All Options Available.</span></b>*/}
								            </div>
								        </div>
								        <div className="row mrg20B">
								            <div className="col-md-3 text-center">
								                {/*<h5>Use the following 3rd party solution to reprice eBay:</h5>
								                					                <h5><a href="#/" target="_blank">Ecom Syndicator</a> & <a href="#/" target="_blank">WooCommerce by WooHosted</a> are 100% free for active Sku Grid users,</h5>
								                					                <h5>please set them up under "Add-Ons"</h5>*/}
								            </div>
								            <div className="col-md-6">
							            		<div className="form-group">
							            			<select className="form-control" name="config_ebay_us_sync" id="addonSync" initselected="exchange_token">
								                        <option value="">Disabled</option>
			                	                        <option disabled="">WooCommerce by WooHosted is not enabled for your account</option>
			                                            <option value="ecom">Direct API (via eCom Syndicator)</option>
								                        <option value="selro">Selro</option>
								                        <option value="ecomdash">EcomDash</option>
								                        <option value="exchange_token" defaultValue>Update via File Exchange Token</option>
								                        <option value="chrome_extension">Update via Chrome Extension</option>
								                    </select>
							            		</div>
							            		<div className="form-group">
							            			<span className="control-lable">Paste your ebay Exchange Token</span>
							            			<textarea className="form-control" rows="4" name="config_ebay_us_exchange_token"></textarea>
							            		</div>
							            		<div className="form-group">
							            			<span className="control-lable">eBay Seller Name</span>
							            			<input type="text" className="form-control" name="config_ebay_us_exchange_seller_name" />
							            		</div>
							            		<div className="form-group text-center">
							            			<button onClick={this.handleClick} type="button" className="btn btn-primary" >Access File Exchange</button>
							            		</div>
											</div>
								        </div>
								    </div>
								</div>
							</div>
	                    </div>
	                </div>
	            </div>
            </div>
        );
    }
}

function mapState(state) {
	return { apicallloader:state };
}

const actionCreators = {
    saveebay: ebayActions.saveebay
};

const Ebayusas = connect(mapState, actionCreators)(Ebayusa);
//export { connectedLoginPage as Login };

export default Ebayusas;