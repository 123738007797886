import React from 'react';
import Header from '../Header/header';
import Sidebar from '../Sidebar/sidebar';
import { insatntlogout } from '../../helpers/insatntlogout';
class dashboard extends React.Component {

	constructor(props) {
        super(props);
        insatntlogout.logoutInsatnt();
        this.state = {pageTitle:"Dashboard - Stock Arbiship"};
    }

    componentDidMount(){
    	document.title = this.state.pageTitle
    }

    render() {
        return (
        	<div id="page-wrapper">
	    		<Header/>
	    		<Sidebar/>
	    		<div id="page-content-wrapper">
	            	<div id="page-content">
	                    <div className="container">
	                    	<div id="page-title">
						    	<h2>Dashboard</h2>
						    </div>
						    <div className="row mrg20B" style={{display:'none'}}>
						    	<div className="panel">
						    		<div className="panel-body">
									    <div className="row mrg20B">
								            <div className="col-md-6">
								                <a href="#/" title="Example tile shortcut" className="tile-box tile-box-shortcut btn-primary">
								                    <span className="bs-badge badge-absolute">5</span>
								                    <div className="tile-header">
								                        Dashboard
								                    </div>
								                    <div className="tile-content-wrapper">
								                        <i className="glyph-icon icon-dashboard"></i>
								                    </div>
								                </a>
								            </div>
								            <div className="col-md-6">
								                <a href="#/" title="Example tile shortcut" className="tile-box tile-box-shortcut btn-black">
								                    <span className="bs-badge badge-absolute">5</span>
								                    <div className="tile-header">
								                        Orders
								                    </div>
								                    <div className="tile-content-wrapper">
								                        <i className="glyph-icon icon-cogs"></i>
								                    </div>
								                </a>
								            </div>
								        </div>
									</div>
								</div>
							</div>
	                    </div>
	                </div>
	            </div>
            </div>
        );
    }
}

export default dashboard;