import React from 'react';
import { connect } from 'react-redux';
import Header from '../Header/header';
import Sidebar from '../Sidebar/sidebar';
import { history } from '../../helpers/history';
import { insatntlogout } from '../../helpers/insatntlogout';
import { inventoryActions } from '../../actions/inventory.actions';
import PaginationTableComponent from '../PaginationTableComponent';
import Apiloader from '../Apiloader/Apiloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

class Inventory extends React.Component {

	constructor(props) {
        super(props);
        insatntlogout.logoutInsatnt();
        this.props.getinventory();
    	this.state = {
    		pageTitle:"Inventory - Stock Arbiship",
    		showLogin: true,
    		isEditData:"",
    		SourceID: '',
    		Quantity: '',
    		Margin: '',
    		WantedProfit: '',
    		Override: 1,
    		checked:1,
    		loader:false
        };
    }

    componentDidMount(){
    	document.title = this.state.pageTitle
    }

  	handleEdit(e)
  	{
  		if (e) {
  			history.push({
	           pathname: '/editinventory/'+e.row.original.keyId,
	           state: e.row.original
	       });
  		}  		
  	}

    render() {
    	const { inventory } = this.props;

    	var data = [];

    	var columns = [
            {
                Header: 'Item ID',
                accessor: 'ItemID',
            },
            {
                Header: 'Source ID',
                accessor: 'SourceID',
            },
            {
                Header: 'Title',
                accessor: 'Title',
            },
            {
                Header: 'Quantity',
                accessor: 'Quantity',
            },
            {
                Header: 'Sell Price',
                accessor: 'SellPrice',
            },
            {
                Header: 'Source Price',
                accessor: 'SourcePrice',
            },
            {
                Header: 'Margin',
                accessor: 'Margin',
            },
            {
                Header: 'Wanted Profit',
                accessor: 'WantedProfit',
            },
            {
                Header: 'Override',
				accessor: 'Override',
            },
            {
                Header: 'Updated',
                accessor: 'Updated',
            },
            {
                Header: 'Action',
                accessor: 'action',
			}
        ];

		if (inventory && Object.keys(inventory).length !== 0) {
    		if (Object.keys(inventory.inventory).length !== 0) {
				columns = [
					{
						Header: 'Item ID',
						accessor: 'ItemID',
					},
					{
						Header: 'Source ID',
						accessor: 'SourceID',
					},
					{
						Header: 'Title',
						accessor: 'Title',
					},
					{
						Header: 'Quantity',
						accessor: 'Quantity',
					},
					{
						Header: 'Sell Price',
						accessor: 'SellPrice',
					},
					{
						Header: 'Source Price',
						accessor: 'SourcePrice',
					},
					{
						Header: 'Margin',
						accessor: 'Margin',
					},
					{
						Header: 'Wanted Profit',
						accessor: 'WantedProfit',
					},
					{
						Header: 'Override',
						accessor: (row)=> {
							return row.Override==="1" ? <input type='checkbox' className="btn btn-xs" checked={true} disabled={true}/> : <input type='checkbox' className="btn btn-xs" checked={false}  disabled={true}/>;
						}
					},
					{
						Header: 'Updated',
						accessor: 'Updated',
					},
					{
						Header: 'Action',
						accessor: 'action',
						disableSortBy:true,
						Cell: (row)=> (
						<div>
							<button className="btn btn-info" 
								onClick={() => this.handleEdit(row)}
							>
							<FontAwesomeIcon icon={faEdit} />
							</button>
						  </div>
						)}	
				];
			    data = inventory.inventory;
    		}
    	}

    	//var search = "Search for ItemID / SourceID / Title";
    	var search = "Search";

        return (
        	<div id="page-wrapper">
	    		<Header/>
	    		<Sidebar/>
	    		<div id="page-content-wrapper" className="pagin">
	            	<div id="page-content">
	                    <div className="container">
	                    	<div id="page-title">
						    	<h2>Inventory</h2>
						    </div>
						    <div className="row mrg20B">
								<div className="col-md-12">
						    		<div className="panel">
										<div className="panel-body">
											{inventory.loading ? (
												<Apiloader/>
											) : (
												<PaginationTableComponent columns={columns} data={data} search={search} />
											)}
										</div>
									</div>
					            </div>
					        </div>
	                    </div>
	                </div>
	            </div>
            </div>
        );
    }
}

function mapState(state) {
	const { authentication,lister,inventory } = state;
	const {user} = authentication;
    return { user,lister,inventory };
}

const actionCreators = {
    getinventory: inventoryActions.getinventory,
    updateinventory:inventoryActions.updateinventory
};

export default connect(mapState, actionCreators)(Inventory);