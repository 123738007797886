import { combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { ebay } from './ebay.reducer';
import { lister } from './lister.reducer';
import { listersetting } from './listersetting.reducer';
import { marketplacesetting } from './marketplacesetting.reducer';
import { inventory } from './inventory.reducer';

const middleware = [ thunk ];

const rootReducer = combineReducers({
  	authentication,
  	users,
  	ebay,
  	lister,
  	listersetting,
	marketplacesetting,
  	inventory,
  	alert,
},applyMiddleware(middleware));

export default rootReducer;