import React from 'react';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { history } from '../../helpers/history';
import { userActions } from '../../actions/user.actions';
import { configConstants } from '../../constants';

class Login extends React.Component {

	constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            remember:false,
            authentication:{},
            apicallloader: false,
            pageTitle:"Login - Stock Arbiship",
            sessLogin:true,
            sessuser:"Arbiship",
            sessemail:"Login",
            //submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        //this.setState({ submitted: true });
        const { email, password, remember } = this.state;
        if (remember && email && password !== "") {
            localStorage.email = email
            localStorage.password = password
            localStorage.remember = remember
        }
        else
        {
        	localStorage.removeItem('email');
            localStorage.removeItem('password');
            localStorage.removeItem('remember');
        }
        if (email && password) {
            this.setState({ apicallloader: true });
            this.props.login(email, password);
            this.setState({ apicallloader: false });
            // setTimeout(() => {
            //     this.setState({ apicallloader: false });
            // }, 1500);
        }
    }

    onChangeCheckbox(e){
        this.setState({
            remember: e.target.checked
        });
    }

	componentDidMount() {
        this.handleLoad();
        setInterval(async () => {
            let cookieValue = this.getCook('ACCESS-TOKEN');
            if (cookieValue) {
                await this.setState({sessLogin:false});
            }
            else
            {
                await this.setState({sessLogin:true});
            }
        }, 1000);
		this.getUserLoginData();
		if (localStorage.remember && localStorage.email !== "") {
            this.setState({
                remember: true,
                email: localStorage.email,
                password: localStorage.password
            })
        }

        document.title = this.state.pageTitle
    }

    // componentWillUnmount() { 
    //    window.removeEventListener('load', this.handleLoad)  
    // }

    handleLoad() {
        setInterval(async () => {
            let cookieValue = await this.getCook('ACCESS-TOKEN');
            if (cookieValue) {
                await this.setState({sessLogin:false});

                let user = await JSON.parse(localStorage.getItem('user'));

                if (user) {
                }
                else
                {
                    var x = await axios({
                        url: configConstants.API_URL+configConstants.API_USERDATA,
                        method: 'get',
                        headers: {
                            'Authorization': 'Bearer '+cookieValue,
                            // 'Content-Type': 'application/json',
                            // 'Access-Control-Allow-Origin': '*'
                        }
                    })
                    .then(function (response){
                        return response.data.data;
                    })
                    .catch(function (error) {
                        return error;
                    });

                    await this.setState({sessuser:x.first_name+" "+x.last_name,sessemail:x.email});
                }
            }
            else
            {
                await this.setState({sessLogin:true});
            }
        }, 5000);

    }

    getUserLoginData()
    {
    	let cookieValue = this.getCook('ACCESS-TOKEN');
    	if (cookieValue) {
            this.props.checkalreadylogin(cookieValue);
    	}
    	else
    	{
            // Remove Local Cookie
            this.eraseCookie("ACCESS-TOKEN");
            // Remove Local storage
            localStorage.removeItem('user');
            localStorage.removeItem('isValid');

            // Remove Local Cookie
            this.eraseCookie("ACCESS-TOKEN");
            history.push('/');
    	}
    }

    setCookie(name,value,days) {
        // var expires = "";
        // if (days) {
        //     var date = new Date();
        //     date.setTime(date.getTime() + (days*24*60*60*1000));
        //     expires = "; expires=" + date.toUTCString();
        // }
        document.cookie = name + "=" + (value || "")  + "; Domain="+configConstants.COOKIE_DOMAIN+" ; path=/";
    }

    eraseCookie(name) {   
        document.cookie = name +'=; Domain='+configConstants.COOKIE_DOMAIN+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

    getCook(cookiename) 
	{
		// Get name followed by anything except a semicolon
		var cookiestring=RegExp(cookiename+"=[^;]+").exec(document.cookie);
		// Return everything after the equal sign, or an empty string if the cookie name not found
		return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
	}

    handleFrgClick()
    {
        var url = configConstants.FORGOT_PWD_URL;
        var win = window.open(url, '_blank');
        win.focus();
    }

    handleRegClick()
    {
        var url = configConstants.REGISTRATION_URL;
        var win = window.open(url, '_blank');
        win.focus();
    }

    handleLogin()
    {
        var cookiename = "ACCESS-TOKEN";        
        var cookiestring=RegExp(cookiename+"=[^;]+").exec(document.cookie);
        // Return everything after the equal sign, or an empty string if the cookie name not found
        var cookiestring1 = decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
        if(cookiestring1)
        {
            this.props.checkalreadylogin(cookiestring1);    
        }
    }

    render() {
    	//const { loggingIn } = this.props;
        const { email, password, remember, apicallloader, sessLogin } = this.state; //submitted
        return (
			<div className="center-vertical bg-black">
                {apicallloader ?
                    <div id="loading">
                        <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                    :
                    <div id="loading" style={{display:'none'}}>
                        <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                }
			    <div className="center-content row">
                    {sessLogin ?
			        <form onSubmit={this.handleSubmit} id="login-validation" className="center-margin col-xs-11 col-sm-5" autoComplete="off">
			            <h3 className="text-center pad25B font-gray font-size-23">Stock <span className="opacity-80">Arbiship</span></h3>
			            <div id="login-form" className="content-box bg-default">
						    <div className="content-box-wrapper pad20A">
							    <div className="form-group">
						            <label htmlFor="exampleInputEmail1">Email address:</label>
						            <div className="input-group input-group-lg">
						                <span className="input-group-addon addon-inside bg-white font-primary">
						                    <i className="glyph-icon icon-envelope-o"></i>
						                </span>
						                <input required defaultValue={email} onChange={this.handleChange} name="email" type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email" />
						            </div>
						        </div>
						        <div className="form-group">
						            <label htmlFor="exampleInputPassword1">Password:</label>
						            <div className="input-group input-group-lg">
						                <span className="input-group-addon addon-inside bg-white font-primary">
						                    <i className="glyph-icon icon-unlock-alt"></i>
						                </span>
						                <input required defaultValue={password} onChange={this.handleChange} name="password" type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
						            </div>
						        </div>
						        <div className="row">
						            <div className="checkbox-primary col-md-6 height-20">
						                <label className="control-label">
						                    <input checked={remember} onChange={this.onChangeCheckbox} name="remember" type="checkbox" id="loginCheckbox1" className="custom-checkbox"/> Remember me
						                </label>
						            </div>
						            <div className="text-right col-md-6">
						                <a href="#!" onClick={this.handleFrgClick} rel="noopener noreferrer" target="_blank" title="Recover password">Forgot your password?</a>
						            </div>
						        </div>
						        <div className="row pad10T">
		                            <div className="col-md-12 height-20">
		                            	<p>
		                            		Don't have an account?<b> <a href="#!" onClick={this.handleRegClick} rel="noopener noreferrer" target="_blank" title="Sign Up">Sign Up Here</a> </b>
		                            	</p>
		                            </div>
		                        </div>
						    </div>
						    <div className="button-pane">
					        	<button type="submit" className="btn btn-block btn-primary">Login</button>
						    </div>
						</div>
			        </form>
                    :
                    <div className="col-md-4 col-sm-5 col-xs-11 col-lg-3 center-margin">
                        <div className="panel-layout wow bounceInDown animated" style={{visibility:'visible'}}>
                            <div className="panel-content pad0A bg-white">
                                <div className="meta-box meta-box-offset">
                                    <img src="../../assets/logo/logo-content-small.png" alt="" className="meta-image img-bordered img-circle"/>
                                    <h3 className="meta-heading font-size-16 font-black" ><b>{this.state.sessuser}</b></h3>
                                    <h4 className="meta-subheading font-size-13 font-black"><b>{this.state.sessemail}</b></h4>
                                </div>
                                <form action="" className="form-inline block" method="">
                                    <div className="content-box-wrapper pad20A">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <span className="input-group-btn">
                                                    <a href="/" onClick={this.handleLogin} className="btn btn-primary" >Login</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    }
			    </div>
			</div>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
    //return {};
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkalreadylogin:userActions.checkalreadylogin
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };