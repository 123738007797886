import React from 'react';
import { connect } from 'react-redux';
import Header from '../Header/header';
import Sidebar from '../Sidebar/sidebar';
import { insatntlogout } from '../../helpers/insatntlogout';
import { listerActions } from '../../actions/lister.actions';
import Apiloader from '../Apiloader/Apiloader';

import PaginationTableComponent from '../PaginationTableComponent';

class Listingrequests extends React.Component {

	constructor(props) {
        super(props);
        insatntlogout.logoutInsatnt();

    	this.state = {
    		pageTitle:"Listing Requests - Stock Arbiship",
    		lister : {}
        };
    }

    componentDidMount(){
    	document.title = this.state.pageTitle
    	this.props.getlister();
    }

    render() {

		const { lister } = this.props;

		var columns = [
            {
                Header: 'Time Requested',
                accessor: 'Date',
            },
            {
                Header: 'Source Market',
                accessor: 'Source_Market',
            },
            {
                Header: 'Number of Items',
                accessor: 'Number_Of_Items',
            },
            {
                Header: 'Status',
                accessor: 'Status',
            }
        ];
        
		var data = [];

		if (lister && Object.keys(lister).length !== 0) {
    		if (Object.keys(lister.lister).length !== 0) {
    			data = lister.lister;
				columns = [
					{
						Header: 'Time Requested',
						accessor: 'Date',
					},
					{
						Header: 'Source Market',
						accessor: 'Source_Market',
					},
					{
						Header: 'Number of Items',
						accessor: 'Number_Of_Items',
					},
					{
						Header: 'Status',
						accessor: 'Status',
					}
				];
    		}
    	}

    	var search = "Search";

        return (
        	<div id="page-wrapper">
	    		<Header/>
	    		<Sidebar/>
	    		<div id="page-content-wrapper" className="pagin">
	            	<div id="page-content">
	                    <div className="container">
	                    	<div id="page-title">
						    	<h2>Listing Requests</h2>
						    </div>
						    <div className="row mrg20B">
						    	<div className="panel">
						    		<div className="panel-body">
										{lister.loading ? (
												<Apiloader/>
											) : (
												<PaginationTableComponent columns={columns} data={data} search={search} />
											)}
						            </div>
					            </div>
					        </div>
	                    </div>
	                </div>
	            </div>
            </div>
        );
    }
}

function mapState(state) {
	const { authentication,lister } = state;
	const {user} = authentication;
    return { user,lister };
}

const actionCreators = {
    getlister: listerActions.getlister
};

const Listingrequestss = connect(mapState, actionCreators)(Listingrequests);

export default Listingrequestss;
