import axios from 'axios';
import { configConstants } from '../constants';
import { authHeader } from '../helpers/auth-header';
export const userService = {
    login,
    logout,
    checkalreadylogin
};

// Send Post Request To Login with Parameters
function login(email, password, ACCESS_TOKEN) {

    return axios
        .post(configConstants.API_URL+configConstants.API_LOGIN, {email:email,password:password,ACCESS_TOKEN:ACCESS_TOKEN})
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Post Request To Logout with Auth Header
function logout() {
    return axios
        .post(configConstants.API_URL+configConstants.API_LOGOUT, {},{headers:authHeader()})
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Get Request To Check Already Login with Auth Header
function checkalreadylogin(cookieValue) {
    return axios({
            url: configConstants.API_URL+configConstants.API_USERDATA,
            method: 'get',
            headers: {
                'Authorization': 'Bearer '+cookieValue,
                // 'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*'
            }
        })
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}