import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from './helpers/history';
import {Login} from './componant/Login/Login';
import Dashboard from './componant/Dashboard/dashboard';
import Ebayusa from './componant/Ebayusa/Ebayusa';
import Marketplace from './componant/Marketplace/Marketplace';
import Lister from './componant/Lister/Lister';
import Listingsettings from './componant/Lister/Listingsettings';
import Listingrequests from './componant/Lister/Listingrequests';
import Inventory from './componant/Inventory/Inventory';
import Editinventory from './componant/Inventory/Editinventory';
import {Logout} from './componant/Logout/Logout';

// Private Routes
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route 
        {...rest} 
        render={props => (
            localStorage.getItem('user')
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )}
    />
)

class App extends React.Component {

    constructor(props) {
        super(props);
        history.listen((location, action) => {});
    }

    render() {
        return (
            <Router history={history}>
                <Switch>
                    <PrivateRoute path="/inventory" component={Inventory} exact />
                    <PrivateRoute path="/lister" component={Lister} exact />
                    <PrivateRoute path="/listingsettings" component={Listingsettings} exact />
                    <PrivateRoute path="/listingrequests" component={Listingrequests} exact />
                    <PrivateRoute path="/editinventory/:id" component={Editinventory} exact />
                    <PrivateRoute path="/marketplace" component={Marketplace} exact />
                    <PrivateRoute path="/ebayusa" component={Ebayusa} exact />
                    <PrivateRoute path="/dashboard" component={Dashboard} exact />
                    <PrivateRoute path="/signout" component={Logout} exact />
                    <Route path="/login" component={Login} />
                    <Route path="/" component={Login} />
                    <Redirect from="*" to="/" />
                </Switch>
            </Router>
        );
    }
}

export default App;