import React from 'react';
import { connect } from 'react-redux';
import Header from '../Header/header';
import Sidebar from '../Sidebar/sidebar';
//import { history } from '../../helpers/history';
//import { configConstants } from '../../constants';
import { insatntlogout } from '../../helpers/insatntlogout';
import { listerActions } from '../../actions/lister.actions';
import Apiloader from '../Apiloader/Apiloader';
//import {Error} from '../Error';

class Lister extends React.Component {

	constructor(props) {
        super(props);
		insatntlogout.logoutInsatnt();
    	this.state = {
    		pageTitle:"Bulk List - Stock Arbiship",
    		source:"Amazon US",
    		source_ids:"",
    		errors:{},
        };
        this.handleChange = this.handleChange.bind(this);
        this.listerSubmit = this.listerSubmit.bind(this);
        this.convert = this.convert.bind(this);
    }

    componentDidMount(){
    	document.title = this.state.pageTitle;
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleValidation(){
        let source = this.state.source;
        let source_ids = this.state.source_ids;
        let errors = {};
        let formIsValid = true;

        console.log(source_ids);

        //source
        if(!source){
           formIsValid = false;
           errors["source"] = "This field is required";
        }
   
        //source_ids
        if(!source_ids || !source_ids.trim()){
           formIsValid = false;
           errors["source_ids"] = "This field is required";
        } 

       	this.setState({errors: errors});
       	return formIsValid;
   	}
        
   	listerSubmit(e){
        e.preventDefault();
        const { user } = this.props;
        const { source, source_ids } = this.state;
        if(this.handleValidation()){
           	this.props.savelister(source, source_ids, user);
        }
    }

	convert(value) {
		const sentence = value;
		this.setState({source_ids: sentence});

		// const sentence = value.replace(/\s+/g, '').trim();
		// if (sentence) {
		// 	var s = value.split('\n');
  //       	this.setState({source_ids: s});
		// }
		// else
		// {
		// 	this.setState({source_ids: ""});
		// }
		/*console.log(sentence);
		var s = value.split('\n');
		this.setState({source_ids: s});*/
    }

    render() {
		const { lister } = this.props;
    	const { source, source_ids } = this.state;
        return (
        	<div id="page-wrapper">
	    		<Header/>
	    		<Sidebar/>
	    		<div id="page-content-wrapper">
	            	<div id="page-content">
	                    <div className="container">
	                    	<div id="page-title">
						    	<h2>Bulk List</h2>
						    </div>
						    <div className="row mrg20B">
						    	<div className="panel">
						    		<div className="panel-body">
						    			<div className="row">
								            <div className="col-md-12">
								            	<ol>
								            		<b>
								            			<li>Select the source market you're listing from.</li>
														<li>Paste in the product IDs / urls of what you want to list.</li>
														<li>We'll show you products we found in green. Then, click list.</li>
														<li>View your Listings Requests to monitor progress.</li>
													</b>
							            		</ol>
								            </div>
							            </div>
							            <form className="form-horizontal bordered-row" id="" onSubmit= {this.listerSubmit}>
										    <div className="row">
										        <div className="col-md-6">
										            <div className="form-group">
										                <label className="control-label">Source Market:</label>
									                    <select className="form-control" 
									                    		name="source" 
									                    		id="source" 
									                    		onChange={this.handleChange} 
									                    		value={source}>
							            					<option value="Amazon US">Amazon US</option>
							            					<option value="Amazon UK">Amazon UK</option>
							            					<option value="Amazon DE">Amazon DE</option>
							            				</select>
							            				<span style={{color: "red"}}>{this.state.errors["source"]}</span>
										            </div>
										        </div>
										    </div>
										    <div className="row">
										        <div className="col-md-12">
										            <div className="form-group">
									                    <textarea className="form-control" 
															style={{resize:"none"}}
							                    			rows="10" 
							                    			name="source_ids" 
							                    			id="source_ids" 
							                    			onChange={(e) => {this.convert(e.target.value)}} 
							                    			onPaste={(e) => {this.convert(e.target.value)}}
							                    			value={source_ids}>
						                    			</textarea>
						                    			<span style={{color: "red"}}>{this.state.errors["source_ids"]}</span>
										            </div>
										        </div>
										    </div>
										    <div className="row">
										    	<div className="col-md-12">
									        		<button className="col-md-3 btn btn-primary" disabled={lister.issubmit}>List Now</button>
													{lister.loading ? (
														<Apiloader/>
													) : (
														""
													)}
									        	</div>
										    </div>
										</form>
						            </div>
					            </div>
					        </div>
	                    </div>
	                </div>
	            </div>
            </div>
        );
    }
}

//export default Lister;

function mapState(state) {
	//return { };
	const { authentication,lister } = state;
    const { user } = authentication;
    return { user,lister };
}

const actionCreators = {
    savelister: listerActions.savelister
};

const Listers = connect(mapState, actionCreators)(Lister);

export default Listers;