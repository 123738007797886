import { listerConstants } from '../constants';

const initialState = {
  loading: false,
  issubmit:false,
  lister: {}
}

export function lister(state = initialState, action) {
  switch (action.type) {
    case listerConstants.SAVE_REQUEST:
      return {
        loading: true,
        issubmit:true,
        lister: action.lister
      };
    case listerConstants.SAVE_SUCCESS:
      return {
        loading: false,
        issubmit:false,
        lister: action.lister
      };
    case listerConstants.SAVE_FAILURE:
      return { 
        loading: false,
        issubmit:false,
        error: action.error
      };
    case listerConstants.GET_REQUEST:
      return {
        loading: true,
        issubmit:true,
        lister: action.lister,
      };
    case listerConstants.GET_SUCCESS:
      return {
        loading: false,
        issubmit:false,
        lister: action.lister
      };
    case listerConstants.GET_FAILURE:
      return { 
        loading: false,
        issubmit:false,
        error: action.error,
      };
    default:
      return state
  }
}