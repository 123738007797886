import React from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import { configConstants } from '../../constants';
import { userActions } from '../../actions/user.actions';


class sidebar extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            opensubmenu: false
        };
        this.checkLoginorNot = this.checkLoginorNot.bind(this);
        this.openNav = this.openNav.bind(this);
    }

    componentDidMount() {
        
        setInterval(async () => {
        	await this.checkLoginorNot();
        },10000);
        this.openNav();
    }

    checkLoginorNot()
    {
    	let cookieValue = this.getCookie('ACCESS-TOKEN');
     	this.setState({first_name:"",last_name:""});
    	if (cookieValue && cookieValue !== "") {
    	}
    	else
    	{
    		if (localStorage.getItem('user')) {
    			this.props.logout();
    		}
            this.eraseCookie("ACCESS-TOKEN");
            localStorage.removeItem('user');
            localStorage.removeItem('isValid');
            this.eraseCookie("ACCESS-TOKEN");
            history.push('/');
    	}
    }

    getCookie(cookiename) 
	{
		var cookiestring=RegExp(cookiename+"=[^;]+").exec(document.cookie);
		return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
	}

	eraseCookie(name) {   
        document.cookie = name +'=; Domain='+configConstants.COOKIE_DOMAIN+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	openNav()
	{
		const currentState = this.state.opensubmenu;
        this.setState({ opensubmenu: !currentState });

    	var element = document.getElementById("subLi");
        if (this.state.opensubmenu === true) {
  			element.classList.add("sfHover");
  			var el = document.getElementsByClassName("sidebar-submenu")[0];
			el.style.display = "block";
        }
        else
        {
  			element.classList.remove("sfHover");
  			var el1 = document.getElementsByClassName("sidebar-submenu")[0];
			el1.style.display = "none";
        }
	}

    render() {
        return (
        	<div id="page-sidebar">
	            <div className="scroll-sidebar">
	                <ul id="sidebar-menu">
	                    <li>
	                        <NavLink to="/dashboard" title="Admin Dashboard" onClick={this.checkLoginorNot} activeClassName="sfActive" activeStyle={{color: '#1aa2d8'}}>
	                            <i className="glyph-icon icon-linecons-tv"></i>
	                    		<span>Dashboard</span>
		            		</NavLink>
	                    </li>
	                    <li className="divider"></li>
	                    <li className="no-menu">
	                        <NavLink to="/ebayusa" title="Ebay USA (US)" onClick={this.checkLoginorNot} activeClassName="sfActive" activeStyle={{color: '#1aa2d8'}}>
	                            <i className="glyph-icon icon-linecons-beaker"></i>
	                    		<span>Ebay USA (US)</span>
		            		</NavLink>
	                    </li>
	                    <li className="divider"></li>
	                    <li className="no-menu">
	                        <NavLink to="/marketplace" title="Marketplace Settings" onClick={this.checkLoginorNot} activeClassName="sfActive" activeStyle={{color: '#1aa2d8'}}>
	                            <i className="glyph-icon icon-linecons-diamond"></i>
	                    		<span>Marketplace Settings</span>
		            		</NavLink>
	                    </li>
	                    <li className="divider"></li>
	                    <li id="subLi">
					        <a href="#/" onClick={this.openNav} title="Lister" className="tests" >
					            <i className="glyph-icon icon-linecons-cog"></i>
					            <span>Lister</span>
					        </a>
					        <div className="sidebar-submenu">
					            <ul>
					                <li>
					                	<NavLink to="/lister" title="Bulk List" onClick={this.checkLoginorNot} activeClassName="sfActive" activeStyle={{color: '#1aa2d8'}}>
				                    		<span>Bulk List</span>
					            		</NavLink>
				            		</li>
					                <li>
					                	<NavLink to="/listingrequests" title="Listing Requests" onClick={this.checkLoginorNot} activeClassName="sfActive" activeStyle={{color: '#1aa2d8'}}>
				                    		<span>Listing Requests</span>
					            		</NavLink>
					            	</li>
					                <li>
					                	<NavLink to="/listingsettings" title="Listing Settings" onClick={this.checkLoginorNot} activeClassName="sfActive" activeStyle={{color: '#1aa2d8'}}>
				                    		<span>Listing Settings</span>
					            		</NavLink>
					                </li>
					            </ul>
					        </div>
					    </li>
	                    <li className="divider"></li>
	                    <li className="no-menu">
	                    	<NavLink to="/inventory" title="Inventory" onClick={this.checkLoginorNot} activeClassName="sfActive" activeStyle={{color: '#1aa2d8'}}>
	                            <i className="glyph-icon icon-linecons-wallet"></i>
	                    		<span>Inventory</span>
		            		</NavLink>
	                    </li>
	                    <li className="divider"></li>
	                    <li className="no-menu">
	                    	<NavLink to="/signout" title="Logout" activeClassName="sfActive" activeStyle={{color: '#1aa2d8'}}>
	                    		<i className="glyph-icon icon-linecons-lock"></i>
	                    		<span>Logout</span>
		            		</NavLink>
	                    </li>
	                </ul>
	            </div>
	        </div>
        );
    }
}


function mapState(state) {
    return { };
}

const actionCreators = {
    logout: userActions.logout
};

const sidebar1 = connect(mapState, actionCreators)(sidebar);
export default sidebar1