import { alertConstants } from '../constants';
import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      new Noty({
          type:"success",
          text: action.message,
          theme:"bootstrap-v4",
          layout: 'topRight',
          timeout:3000,
          progressBar:true,
          closeWith:['click','button']
      }).show();
      return {};
    case alertConstants.ERROR:
      new Noty({
          type:"error",
          text: action.message,
          theme:"bootstrap-v4",
          layout: 'topRight',
          timeout:3000,
          progressBar:true,
          closeWith:['click','button']
      }).show();
      return {};
    default:
      return state
  }
}