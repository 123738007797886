import { history } from '../helpers/history';
import { alertActions } from './alert.actions';

export const ebayActions = {
    saveebay
};

function saveebay(email, password) {
    return dispatch => {
        dispatch(alertActions.success("Save Successfully!"));
        history.push('/ebayusa');
    };
}