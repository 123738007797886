import { history } from '../helpers/history';
import { alertActions } from './alert.actions';
import { listerConstants } from '../constants/lister.constants';
import { listerService } from '../services/lister.service';

export const listerActions = {
    savelister,
    getlister,
    addlistersetting,
    getlistersetting,
    updatelistersetting
};

// Dispatch Save Listner Action
function savelister(source, source_ids, user) {
    return async dispatch => {
        dispatch(request({  }));

        var email = user.email;

        listerService.savelister(source, source_ids, email)
            .then(
                lister => { 
                    if (lister) {
                        if (lister.status === "200") {
                            dispatch(success(lister.data));
                            history.push('/listingrequests');
                            dispatch(alertActions.success(lister.message));
                        }
                        else
                        {
                            dispatch(failure(lister.data));
                            history.push('/lister');
                            dispatch(alertActions.error(lister.message));
                        }
                    }
                    else
                    {
                        //dispatch(failure(lister.data));
                        history.push('/lister');
                        dispatch(alertActions.error(lister.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(lister) { return { type: listerConstants.SAVE_REQUEST, lister } }
    function success(lister) { return { type: listerConstants.SAVE_SUCCESS, lister } }
    function failure(error) { return { type: listerConstants.SAVE_FAILURE, error } }
}

// Dispatch Get Lister Action
function getlister(argument) {
    return dispatch => {
        dispatch(request({  }));
        listerService.getlister()
            .then(
                lister => { 
                    if (lister) {
                        if (lister.status === "200") {
                            dispatch(success(lister.data));
                            history.push('listingrequests');
                            //dispatch(alertActions.success(lister.message));
                        }
                        else
                        {
                            dispatch(failure(lister.data));
                            history.push('listingrequests');
                            //dispatch(alertActions.success(lister.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(lister.data));
                        history.push('listingrequests');
                        //dispatch(alertActions.error(lister.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('listingrequests');
                    //dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(lister) { return { type: listerConstants.GET_REQUEST , lister } }
    function success(lister) { return { type: listerConstants.GET_SUCCESS , lister} }
    function failure(error) { return { type: listerConstants.GET_FAILURE } }
}

// Dispatch Add Lister Setting Action
function addlistersetting(stateValue)
{
    return async dispatch => {
        dispatch(request({  }));
        listerService.addlistersetting(stateValue)
            .then(
                listersetting => { 
                    if (listersetting) {
                        if (listersetting.status === "200") {
                            dispatch(success(listersetting.data));
                            history.push('/listingsettings');
                            dispatch(alertActions.success(listersetting.message));
                        }
                        else
                        {
                            dispatch(failure(listersetting.data));
                            history.push('/listingsettings');
                            dispatch(alertActions.error(listersetting.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(listersetting.data));
                        history.push('/listingsettings');
                        dispatch(alertActions.error(listersetting.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('/listingsettings');
                    dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(listersetting) { return { type: listerConstants.ADD_LST_SAVE_REQUEST, listersetting } }
    function success(listersetting) { return { type: listerConstants.ADD_LST_SAVE_SUCCESS, listersetting } }
    function failure(listersetting) { return { type: listerConstants.ADD_LST_SAVE_FAILURE, listersetting } }
}

// Dispatch Get Lister Setting Action
function getlistersetting()
{
    return dispatch => {
        dispatch(request({  }));
        listerService.getlistersetting()
            .then(
                listersetting => { 
                    if (listersetting) {
                        if (listersetting.status === "200") {
                            dispatch(success(listersetting.data));
                            history.push('listingsettings');
                        }
                        else
                        {
                            dispatch(failure(listersetting.data));
                            history.push('listingsettings');
                        }
                    }
                    else
                    {
                        dispatch(failure(listersetting.data));
                        history.push('listingsettings');
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('listingsettings');
                }
            );
    };

    function request(listersetting) { return { type: listerConstants.GET_LST_SAVE_REQUEST , listersetting } }
    function success(listersetting) { return { type: listerConstants.GET_LST_SAVE_SUCCESS , listersetting} }
    function failure(listersetting) { return { type: listerConstants.GET_LST_SAVE_FAILURE, listersetting } }
}

// Dispatch Update Lister Setting Action
function updatelistersetting(stateValue)
{
    return async dispatch => {
        dispatch(request({  }));
        listerService.updatelistersetting(stateValue)
            .then(
                listersetting => { 
                    if (listersetting) {
                        if (listersetting.status === "200") {
                            dispatch(success(listersetting.data));
                            history.push('/listingsettings');
                            dispatch(alertActions.success(listersetting.message));
                        }
                        else
                        {
                            dispatch(failure(listersetting.data));
                            history.push('/listingsettings');
                            dispatch(alertActions.error(listersetting.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(listersetting.data));
                        history.push('/listingsettings');
                        dispatch(alertActions.error(listersetting.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('/listingsettings');
                    dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(listersetting) { return { type: listerConstants.UPDATE_LST_SAVE_REQUEST, listersetting } }
    function success(listersetting) { return { type: listerConstants.UPDATE_LST_SAVE_SUCCESS, listersetting } }
    function failure(listersetting) { return { type: listerConstants.UPDATE_LST_SAVE_FAILURE, listersetting } }
}