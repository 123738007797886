import React from 'react';
import { connect } from 'react-redux';
import Header from '../Header/header';
import Sidebar from '../Sidebar/sidebar';
import { history } from '../../helpers/history';
import { insatntlogout } from '../../helpers/insatntlogout';
import { inventoryActions } from '../../actions/inventory.actions';
import Apiloader from '../Apiloader/Apiloader';

class Editinventory extends React.Component {

	constructor(props) {
		super(props);
		insatntlogout.logoutInsatnt();
		this.toggleChange = this.toggleChange.bind(this);
		var x = 0;
		if(this.props.location.state.Override === "true" ||  this.props.location.state.Override === true ||  this.props.location.state.Override === 1 ||  this.props.location.state.Override === "1")
		{
			x = 1;
		}

		this.state = {
			pageTitle: "Inventory - Stock Arbiship",
			isEditData: this.props.location.state ? this.props.location.state.keyId : "",
			SourceID: this.props.location.state ? this.props.location.state.SourceID : "",
			Quantity: this.props.location.state ? this.props.location.state.Quantity : "",
			Margin: this.props.location.state ? this.props.location.state.Margin : "",
			WantedProfit: this.props.location.state ? this.props.location.state.WantedProfit : "",
			Override: x,
			loading: false,
			issubmit:false,
			errors: {},
			all: this.props.location.state ? this.props.location.state : {}
		};
	}

	componentDidMount() {
		document.title = this.state.pageTitle;
	}

	handleChange(dataType, value) {
		if (dataType === "SourceID") {
			this.setState({ SourceID: value });
		}
		if (dataType === "Quantity") {
			this.setState({ Quantity: value });
		}
		if (dataType === "Margin") {
			this.setState({ Margin: value });
		}
		if (dataType === "WantedProfit") {
			this.setState({ WantedProfit: value });
		}
	}

	async toggleChange(e,val,chk){
		//const checkeds = e.target.checked;
		await this.setState({Override: 0},()=>{});
	}

	handleValidation() {
		const currentstateValue = this.state;
		var Margin = currentstateValue.Margin;
		var Quantity = currentstateValue.Quantity;
		var SourceID = currentstateValue.SourceID;
		var WantedProfit = currentstateValue.WantedProfit;
		let errors = {};
		let formIsValid = true;

		if (!Margin || !Margin.trim()) {
			formIsValid = false;
			errors["Margin"] = "This field is required";
		}

		if (!Quantity || !Quantity.trim()) {
			formIsValid = false;
			errors["Quantity"] = "This field is required";
		}

		if (!SourceID || !SourceID.trim()) {
			formIsValid = false;
			errors["SourceID"] = "This field is required";
		}

		if (!WantedProfit || !WantedProfit.trim()) {
			formIsValid = false;
			errors["WantedProfit"] = "This field is required";
		}

		this.setState({ errors: errors });
		return formIsValid;
	}

	saveInventory(e) {
		if(this.handleValidation()){
			const currentstateValue = this.state;
			var Margin = currentstateValue.Margin;
			var Override = currentstateValue.Override;
			var Quantity = currentstateValue.Quantity;
			var SourceID = currentstateValue.SourceID;
			var WantedProfit = currentstateValue.WantedProfit;
			var isEditData = currentstateValue.isEditData;
			this.setState({issubmit: true,loading: true});
			this.props.updateinventory(Margin, Override, Quantity, SourceID, WantedProfit, isEditData);
		}
	}

	handleCancel(e) {
		history.push('/inventory');
	}

	render() {
		const { SourceID, Quantity, Margin, WantedProfit, Override,all } = this.state;
		const { inventory } = this.props;
		return (
			<div id="page-wrapper">
				<Header />
				<Sidebar />
				<div id="page-content-wrapper" className="pagin">
					<div id="page-content">
						<div className="container">
							<div id="page-title">
								<h2>Edit Inventory</h2>
							</div>
							<div className="row mrg20B">
								<div className="panel">
									<div className="panel-body">
										<div className="form-horizontal">
											<div className="form-group">
												<label className="col-sm-3 control-label">Source ID</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" name="SourceID" maxLength="20" defaultValue={SourceID} onChange={(e) => this.handleChange('SourceID', e.target.value)} />
													<span style={{color: "red"}}>{this.state.errors["SourceID"]}</span>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label">Quantity</label>
												<div className="col-sm-6">
													<input type="text" 
														className="form-control" 
														pattern="/[0-9]*/" 
														name="Quantity" 
														maxLength="10" 
														min="0" 
														defaultValue={Quantity} 
														onChange={(e) => this.handleChange('Quantity', e.target.value)}
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
															  event.preventDefault();
															}
														  }} 
														onPaste={(event) => {
															if (!/[0-9]/.test(event.key)) {
															  event.preventDefault();
															}
														  }} 
													/>
													<span style={{color: "red"}}>{this.state.errors["Quantity"]}</span>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label">Margin</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" name="Margin" maxLength="10" defaultValue={Margin} onChange={(e) => this.handleChange('Margin', e.target.value)} />
													<span style={{color: "red"}}>{this.state.errors["Margin"]}</span>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label"> Wanted Profit</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" name="WantedProfit" maxLength="10" defaultValue={WantedProfit} onChange={(e) => this.handleChange('WantedProfit', e.target.value)} />
													<span style={{color: "red"}}>{this.state.errors["WantedProfit"]}</span>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label">Override</label>
												<div className="col-sm-6">
													<input
														name="Override"
														type="checkbox"
														checked={Override}
														defaultValue={Override}
														className="btn btn-xs"
														disabled={Override===1 ? 0 : 1}
														onChange={(e) => this.toggleChange(e,e.target.value,e.target.checked)} />
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label">ItemId</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" defaultValue={all.ItemID} readOnly onChange={(e) => e.preventDefault()} />
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label">Title</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" defaultValue={all.Title} readOnly onChange={(e) => e.preventDefault()} />
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label">SellPrice</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" defaultValue={all.SellPrice} readOnly onChange={(e) => e.preventDefault()} />
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label">SourcePrice</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" defaultValue={all.SourcePrice} readOnly onChange={(e) => e.preventDefault()} />
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-3 control-label">Updated</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" defaultValue={all.Updated} readOnly onChange={(e) => e.preventDefault()} />
												</div>
											</div>
											<div className="text-center">
												{inventory.loading ? (
													<Apiloader/>
												) : (
													<>
														<button className="btn btn-primary mr-10" disabled={inventory.issubmit} onClick={(e) => this.saveInventory(e)} >Submit</button>
														<button className="btn btn-danger" disabled={inventory.issubmit} onClick={(e) => this.handleCancel(e)} >Cancel</button>
													</>
												)}

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapState(state) {
	const { authentication, lister, inventory } = state;
	const { user } = authentication;
	return { user, lister, inventory };
}

const actionCreators = {
	updateinventory: inventoryActions.updateinventory
};

export default connect(mapState, actionCreators)(Editinventory);