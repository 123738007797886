import React from 'react';

const Apiloader = (props) => {
    return (
        <div className="loading-spinner">
            <i className="bg-primary"></i>
            <i className="bg-primary"></i>
            <i className="bg-primary"></i>
            <i className="bg-primary"></i>
            <i className="bg-primary"></i>
            <i className="bg-primary"></i>
        </div>
    );
}
  
export default Apiloader;