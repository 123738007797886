import React from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination, useAsyncDebounce } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp,faCaretDown,faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    search
}) {
    //const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                className="form-control"
                                value={value || ""}
                                onChange={e => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}
                                placeholder={search}
                            />
                            <span className="input-group-addon" 
                                onClick={(e) => {setValue(""); onChange("");}}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                        </div>
                    </div>  
                </div>
            </div>
        </>
    )
}


function Table({ columns, data, search }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        //rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )
    var x = 0;
    if(pageIndex === 0 && pageCount === 0){
        x = pageIndex +" of "+ pageOptions.length;
    }
    else{
        x = pageIndex+1 +" of "+ pageOptions.length;
    }

    // Render the UI for your table
    return (
        <>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                search={search}
            />
            <div className="row mt-20">
                <div className="col-md-12">
                    <div className="table-info">
                        <div className="table-responsive">
                            <table className="table" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                // Add the sorting props to control sorting. For this example
                                                // we can add them into the header props
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render('Header')}
                                                    {/* Add a sort direction indicator */}
                                                    <span>
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <FontAwesomeIcon icon={faCaretDown} />
                                                                : <FontAwesomeIcon icon={faCaretUp} />
                                                            : ''}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {(page.length > 0 && page.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        )
                                    })) || <tr><td colSpan={11} className="text-center" ><b>No Records Found</b></td></tr> }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-20">
                <div className="col-md-4">
                    <ul className="pagination">
                        <li>
                            <select
                                className="form-control"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </li>
                    </ul>
                </div>
                <div className="col-md-8">
                    <ul className="pagination fl-right">
                        <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <span className="page-link">First</span>
                        </li>
                        <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <span className="page-link">{'<'}</span>
                        </li>
                        <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                            <span className="page-link">{'>'}</span>
                        </li>
                        <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <span className="page-link">Last</span>
                        </li>
                        <li className="page-item mr-10">
                            <span className="page-link">
                                Page{' '}
                                <strong>
                                    {/* {pageIndex + 1} of {pageOptions.length} */}
                                    {x}
                                </strong>{' '}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

function PaginationTableComponent({ columns, data, search }) {

    return (
        <Table columns={columns} data={data} search={search} />
    )
}

export default PaginationTableComponent;