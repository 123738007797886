import { marketplaceConstants } from '../constants';

const initialState = {
  loading: false,
  issubmit:false,
  marketplacesetting: []
}

export function marketplacesetting(state = initialState, action) {
  switch (action.type) {
    case marketplaceConstants.ADD_MP_SAVE_REQUEST:
      return {
        loading: true,
        issubmit:true,
        marketplacesetting: action.marketplacesetting
      };
    case marketplaceConstants.ADD_MP_SAVE_SUCCESS:
      return {
        loading: false,
        issubmit:false,
        marketplacesetting: action.marketplacesetting
      };
    case marketplaceConstants.ADD_MP__SAVE_FAILURE:
      return { 
        loading: false,
        issubmit:false,
        marketplacesetting: action.marketplacesetting
      };
    case marketplaceConstants.GET_MP_SAVE_REQUEST:
      return {
        loading: true,
        issubmit:true,
        marketplacesetting: action.marketplacesetting,
      };
    case marketplaceConstants.GET_MP_SAVE_SUCCESS:
      return {
        loading: false,
        issubmit:false,
        marketplacesetting: action.marketplacesetting
      };
    case marketplaceConstants.GET_MP_SAVE_FAILURE:
      return { 
        loading: false,
        issubmit:false,
        marketplacesetting: action.marketplacesetting
      };
    case marketplaceConstants.UPDATE_MP_SAVE_REQUEST:
      return {
        loading: true,
        issubmit:true,
        marketplacesetting: action.marketplacesetting,
      };
    case marketplaceConstants.UPDATE_MP_SAVE_SUCCESS:
      return {
        loading: false,
        issubmit:false,
        marketplacesetting: action.marketplacesetting
      };
    case marketplaceConstants.UPDATE_MP_SAVE_FAILURE:
      return { 
        loading: false,
        issubmit:false,
        marketplacesetting: action.marketplacesetting
      };
    default:
      return state
  }
}