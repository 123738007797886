import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions/user.actions';
class Logout extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            apicallloader: false
        };
    }

    componentDidMount() {
        this.setState({ apicallloader: true });
    	this.props.logout();
    }

    render() {
        const { apicallloader } = this.state;
    	return (<div>{apicallloader ?
                    <div id="loading">
                        <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                    :
                    <div id="loading" style={{display:'none'}}>
                        <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                }</div>);
    }
}

function mapState(state) {
    //const { loggingIn } = state.authentication;
    //return { loggingIn };
    return { };
}

const actionCreators = {
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(Logout);
export { connectedLoginPage as Logout };