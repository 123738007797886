import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { insatntlogout } from '../../helpers/insatntlogout';
class header extends React.Component {

	constructor(props) {
        super(props);
        insatntlogout.logoutInsatnt();
        this.state = {
            active: false,
            pageTitle: "Stock Arbiship"
        };

        this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {
		const currentState = this.state.active;
        this.setState({ active: !currentState });
        if (this.state.active === true) {
        	document.body.classList.add('closed-sidebar');
        }
        else
        {
        	document.body.classList.remove('closed-sidebar');
        }
    }

    componentDidMount(){
    	this.handleClick();
    	document.title = this.state.pageTitle
    }

    render() {
    	const { user } = this.props;
        return (
        	<div id="page-header" className="bg-gradient-9">
	            <div id="mobile-navigation">
	                <button id="nav-toggle" className="collapsed" data-toggle="collapse" data-target="#page-sidebar"><span></span></button>
	                <Link to="/dashboard" className="logo-content-small" title="Stock Arbiship">Stock Arbiship</Link>
	            </div>
	            <div id="header-logo" className="logo-bg">
	                <Link to="/dashboard" className="logo-content-big" title="Stock Arbiship">
	                    Stock <i>Arbiship</i>
	                </Link>
	                <Link to="/dashboard" className="logo-content-small" title="Stock Arbiship">
	                    Stock <i>Arbiship</i>
	                </Link>
	                <a href="#/" id="close-sidebar" onClick={this.handleClick} title="Close sidebar">
	                    <i className={`glyph-icon ${this.state.active ? 'icon-angle-left' : 'icon-angle-right'}`}></i>
	                </a>
	            </div>
	            <div id="header-nav-left">
	                <div className="user-account-btn dropdown">
	                    <a href="#/" onClick={e => e.preventDefault()} title="My Account" className="user-profile clearfix" data-toggle="dropdown">
	                        <span>{user ? user.first_name : "" } {user ? user.last_name : ""}</span>
	                    </a>
	                </div>
	            </div>
	            <div id="header-nav-right">
	                <div className="dropdown" id="notifications-btn" style={{display:'none'}}>
	                    <a data-toggle="dropdown" href="#/" title="">
	                        <span className="small-badge bg-yellow"></span>
	                        <i className="glyph-icon icon-linecons-megaphone"></i>
	                    </a>
	                    <div className="dropdown-menu box-md float-right">

	                        <div className="popover-title display-block clearfix pad10A">
	                            Notifications
	                            <a className="text-transform-cap font-primary font-normal btn-link float-right" href="#/" title="View more options">
	                                More options...
	                            </a>
	                        </div>
	                        <div className="scrollable-content scrollable-slim-box">
	                            <ul className="no-border notifications-box">
	                                <li>
	                                    <span className="bg-danger icon-notification glyph-icon icon-bullhorn"></span>
	                                    <span className="notification-text">This is an error notification</span>
	                                    <div className="notification-time">
	                                        a few seconds ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-warning icon-notification glyph-icon icon-users"></span>
	                                    <span className="notification-text font-blue">This is a warning notification</span>
	                                    <div className="notification-time">
	                                        <b>15</b> minutes ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-green icon-notification glyph-icon icon-sitemap"></span>
	                                    <span className="notification-text font-green">A success message example.</span>
	                                    <div className="notification-time">
	                                        <b>2 hours</b> ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-azure icon-notification glyph-icon icon-random"></span>
	                                    <span className="notification-text">This is an error notification</span>
	                                    <div className="notification-time">
	                                        a few seconds ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-warning icon-notification glyph-icon icon-ticket"></span>
	                                    <span className="notification-text">This is a warning notification</span>
	                                    <div className="notification-time">
	                                        <b>15</b> minutes ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-blue icon-notification glyph-icon icon-user"></span>
	                                    <span className="notification-text font-blue">Alternate notification styling.</span>
	                                    <div className="notification-time">
	                                        <b>2 hours</b> ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-purple icon-notification glyph-icon icon-user"></span>
	                                    <span className="notification-text">This is an error notification</span>
	                                    <div className="notification-time">
	                                        a few seconds ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-warning icon-notification glyph-icon icon-user"></span>
	                                    <span className="notification-text">This is a warning notification</span>
	                                    <div className="notification-time">
	                                        <b>15</b> minutes ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-green icon-notification glyph-icon icon-user"></span>
	                                    <span className="notification-text font-green">A success message example.</span>
	                                    <div className="notification-time">
	                                        <b>2 hours</b> ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-purple icon-notification glyph-icon icon-user"></span>
	                                    <span className="notification-text">This is an error notification</span>
	                                    <div className="notification-time">
	                                        a few seconds ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                                <li>
	                                    <span className="bg-warning icon-notification glyph-icon icon-user"></span>
	                                    <span className="notification-text">This is a warning notification</span>
	                                    <div className="notification-time">
	                                        <b>15</b> minutes ago
	                                        <span className="glyph-icon icon-clock-o"></span>
	                                    </div>
	                                </li>
	                            </ul>
	                        </div>
	                        <div className="pad10A button-pane button-pane-alt text-center">
	                            <a href="#/" className="btn btn-primary" title="View all notifications">
	                                View all notifications
	                            </a>
	                        </div>
	                    </div>
	                </div>
	                <div className="dropdown" id="dashnav-btn" style={{display:'none'}}>
	                    <a href="#/" data-toggle="dropdown" data-placement="bottom" className="popover-button-header tooltip-button" title="Dashboard Quick Menu">
	                        <i className="glyph-icon icon-linecons-cog"></i>
	                    </a>
	                    <div className="dropdown-menu float-right">
	                        <div className="box-sm">
	                            <div className="pad5T pad5B pad10L pad10R dashboard-buttons clearfix">
	                                <a href="#/" className="btn vertical-button hover-blue-alt" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-dashboard opacity-80 font-size-20"></i>
	                                    </span>
	                                    Dashboard
	                                </a>
	                                <a href="#/" className="btn vertical-button hover-green" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-tags opacity-80 font-size-20"></i>
	                                    </span>
	                                    Widgets
	                                </a>
	                                <a href="#/" className="btn vertical-button hover-orange" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-fire opacity-80 font-size-20"></i>
	                                    </span>
	                                    Tables
	                                </a>
	                                <a href="#/" className="btn vertical-button hover-orange" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-bar-chart-o opacity-80 font-size-20"></i>
	                                    </span>
	                                    Charts
	                                </a>
	                                <a href="#/" className="btn vertical-button hover-purple" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-laptop opacity-80 font-size-20"></i>
	                                    </span>
	                                    Buttons
	                                </a>
	                                <a href="#/" className="btn vertical-button hover-azure" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-code opacity-80 font-size-20"></i>
	                                    </span>
	                                    Panels
	                                </a>
	                            </div>
	                            <div className="divider"></div>
	                            <div className="pad5T pad5B pad10L pad10R dashboard-buttons clearfix">
	                                <a href="#/" className="btn vertical-button remove-border btn-info" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-dashboard opacity-80 font-size-20"></i>
	                                    </span>
	                                    Dashboard
	                                </a>
	                                <a href="#/" className="btn vertical-button remove-border btn-danger" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-tags opacity-80 font-size-20"></i>
	                                    </span>
	                                    Widgets
	                                </a>
	                                <a href="#/" className="btn vertical-button remove-border btn-purple" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-fire opacity-80 font-size-20"></i>
	                                    </span>
	                                    Tables
	                                </a>
	                                <a href="#/" className="btn vertical-button remove-border btn-azure" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-bar-chart-o opacity-80 font-size-20"></i>
	                                    </span>
	                                    Charts
	                                </a>
	                                <a href="#/" className="btn vertical-button remove-border btn-yellow" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-laptop opacity-80 font-size-20"></i>
	                                    </span>
	                                    Buttons
	                                </a>
	                                <a href="#/" className="btn vertical-button remove-border btn-warning" title="">
	                                    <span className="glyph-icon icon-separator-vertical pad0A medium">
	                                        <i className="glyph-icon icon-code opacity-80 font-size-20"></i>
	                                    </span>
	                                    Panels
	                                </a>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	                <Link to="/signout" className="header-btn" id="logout-btn" title="Logout">
	                    <i className="glyph-icon icon-linecons-lock"></i>
		            </Link>
	            </div>
	        </div>
        );
    }
}

//export default header;

function mapState(state) {
    //const { authentication } = state;
    //return { authentication };
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const actionCreators = {
}

const headers = connect(mapState, actionCreators)(header);
export default headers; //{ connectedHomePage as header };