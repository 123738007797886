import axios from 'axios';
import { configConstants } from '../constants';
import { authHeader } from '../helpers/auth-header';

export const listerService = {
    savelister,
    getlister,
    addlistersetting,
    getlistersetting,
    updatelistersetting
};

// Send Post Request To Add Listner with Parameters and Auth Header 
function savelister(source, source_ids, email) {
	return axios
        .post(configConstants.API_URL+configConstants.API_ADDLISTER, {source:source,source_ids:source_ids,email:email},{headers:authHeader()})
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Post Request To Get Listner list with Auth Header
function getlister() {
	return axios
        .post(configConstants.API_URL+configConstants.API_GETLISTER, {},{headers:authHeader()})
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Post Request To Add Listner Setting with Parameters and Auth Header
function addlistersetting(stateValue)
{
    var paypalEmail= stateValue.paypalEmail;
    var titleKeyword= stateValue.titleKeyword;
    var locationZip= stateValue.locationZip;
    var locationDisplay= stateValue.locationDisplay;
    var shipFrom= stateValue.shipFrom;
    var maxPrice= stateValue.maxPrice;
    var BrandOverride= stateValue.BrandOverride;

    return axios
        .post(configConstants.API_URL+configConstants.API_ADDLISTERSETTING, {
            paypalEmail:paypalEmail,
            titleKeyword:titleKeyword,
            locationZip:locationZip,
            locationDisplay:locationDisplay,
            shipFrom:shipFrom,
            maxPrice:maxPrice,
            BrandOverride:BrandOverride
        },{
            headers:authHeader()
        })
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Post Request To Get Listner Setting with Auth Header
function getlistersetting()
{
    return axios
        .post(configConstants.API_URL+configConstants.API_GETLISTERSETTING, {},{headers:authHeader()})
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Post Request To Update Listner Setting with Parameters and Auth Header
function updatelistersetting(stateValue)
{
    var paypalEmail= stateValue.paypalEmail;
    var titleKeyword= stateValue.titleKeyword;
    var locationZip= stateValue.locationZip;
    var locationDisplay= stateValue.locationDisplay;
    var shipFrom= stateValue.shipFrom;
    var maxPrice= stateValue.maxPrice;
    var BrandOverride= stateValue.BrandOverride;
    var isEditData= stateValue.isEditData;

    return axios
        .post(configConstants.API_URL+configConstants.API_UPDATELISTERSETTING, {
            paypalEmail:paypalEmail,
            titleKeyword:titleKeyword,
            locationZip:locationZip,
            locationDisplay:locationDisplay,
            shipFrom:shipFrom,
            maxPrice:maxPrice,
            BrandOverride:BrandOverride,
            isEditData:isEditData
        },{
            headers:authHeader()
        })
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}