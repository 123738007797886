import { history } from './history';
import { configConstants } from '../constants';

export const insatntlogout = {
    logoutInsatnt
};

function logoutInsatnt()
{
	var cookieValue = getCookie('ACCESS-TOKEN');
	if (!cookieValue) {
    	// Remove Local storage
        localStorage.removeItem('user');
        localStorage.removeItem('isValid');
        // Remove Local Cookie
        eraseCookie("ACCESS-TOKEN");
        history.push('/');
	}
}

function getCookie(cookiename) 
{
	var cookiestring=RegExp(cookiename+"=[^;]+").exec(document.cookie);
	return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
}

function eraseCookie(name) {   
    document.cookie = name +'=; Domain='+configConstants.COOKIE_DOMAIN+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}