export const listerConstants = {
    SAVE_REQUEST: 'LISTER_SAVE_REQUEST',
    SAVE_SUCCESS: 'LISTER_SAVE_SUCCESS',
    SAVE_FAILURE: 'LISTER_SAVE_FAILURE',
    GET_REQUEST: 'LISTER_GET_REQUEST',
    GET_SUCCESS: 'LISTER_GET_SUCCESS',
    GET_FAILURE: 'LISTER_GET_FAILURE',
    ADD_LST_SAVE_REQUEST: 'ADD_LST_SAVE_REQUEST',
    ADD_LST_SAVE_SUCCESS: 'ADD_LST_SAVE_SUCCESS',
    ADD_LST_SAVE_FAILURE: 'ADD_LST_SAVE_FAILURE',
    GET_LST_SAVE_REQUEST: 'GET_LST_SAVE_REQUEST',
    GET_LST_SAVE_SUCCESS: 'GET_LST_SAVE_SUCCESS',
    GET_LST_SAVE_FAILURE: 'GET_LST_SAVE_FAILURE',
    UPDATE_LST_SAVE_REQUEST: 'UPDATE_LST_SAVE_REQUEST',
    UPDATE_LST_SAVE_SUCCESS: 'UPDATE_LST_SAVE_SUCCESS',
    UPDATE_LST_SAVE_FAILURE: 'UPDATE_LST_SAVE_FAILURE'
};
