import { history } from '../helpers/history';
import { alertActions } from './alert.actions';
import { inventoryConstants } from '../constants/inventory.constants';
import { inventoryService } from '../services/inventory.service';

export const inventoryActions = {
    getinventory,
    updateinventory
};

// Dispatch Get Inventory Action
function getinventory() {
    return dispatch => {
        dispatch(request({  }));
        inventoryService.getinventory()
            .then(
                inventory => { 
                    if (inventory) {
                        if (inventory.status === "200") {
                            dispatch(success(inventory.data));
                            history.push('inventory');
                            //dispatch(alertActions.success(inventory.message));
                        }
                        else
                        {
                            dispatch(failure(inventory.data));
                            history.push('inventory');
                            //dispatch(alertActions.success(inventory.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(inventory.data));
                        history.push('inventory');
                        //dispatch(alertActions.error(inventory.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('inventory');
                    //dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(inventory) { return { type: inventoryConstants.GET_REQUEST , inventory } }
    function success(inventory) { return { type: inventoryConstants.GET_SUCCESS , inventory} }
    function failure(error) { return { type: inventoryConstants.GET_FAILURE } }
}

// Dispatch Update Inventory Action
function updateinventory(Margin,Override,Quantity,SourceID,WantedProfit,isEditData) {
    return async dispatch => {
        dispatch(request({  }));
        inventoryService.updateinventory(Margin,Override,Quantity,SourceID,WantedProfit,isEditData)
            .then(
                inventory => { 
                    if (inventory) {
                        if (inventory.status === "200") {
                            dispatch(success(inventory.data));
                            history.push('/inventory');
                            dispatch(alertActions.success(inventory.message));
                        }
                        else
                        {
                            dispatch(failure(inventory.data));
                            history.push('/inventory');
                            dispatch(alertActions.error(inventory.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(inventory.data));
                        history.push('/inventory');
                        dispatch(alertActions.error(inventory.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error("Error"));
                }
            ).then(
                inventory => { 
                    console.log(inventory);
                    dispatch(getinventory());
            });
    };

    function request(inventory) { return { type: inventoryConstants.SAVE_REQUEST, inventory } }
    function success(inventory) { return { type: inventoryConstants.SAVE_SUCCESS, inventory } }
    function failure(inventory) { return { type: inventoryConstants.SAVE_FAILURE, inventory } }
}