import axios from 'axios';
import { configConstants } from '../constants';
import { authHeader } from '../helpers/auth-header';

export const inventoryService = {
    getinventory,
    updateinventory
};

// Send Post Request To Get Inventory with Auth Header
function getinventory() {
    return axios
        .post(configConstants.API_URL+configConstants.API_GETINVENTORY, {},{headers:authHeader()})
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}

// Send Post Request To Update Inventory with Parameters and Auth Header
function updateinventory(Margin,Override,Quantity,SourceID,WantedProfit,isEditData) {
    return axios
        .post(configConstants.API_URL+configConstants.API_UPDATEINVENTORY, {Margin:Margin,Override:Override,Quantity:Quantity,SourceID:SourceID,WantedProfit:WantedProfit,isEditData:isEditData},{headers:authHeader()})
        .then(function (response){
            if (response.data.status === 200) {
                return response.data;
            }
            else
            {
                return response.data;
            }
        })
        .catch(function (error) {
            return error;
        });
}