export const marketplaceConstants = {
    ADD_MP_SAVE_REQUEST: 'ADD_MP_SAVE_REQUEST',
    ADD_MP_SAVE_SUCCESS: 'ADD_MP_SAVE_SUCCESS',
    ADD_MP_SAVE_FAILURE: 'ADD_MP_SAVE_FAILURE',
    GET_MP_SAVE_REQUEST: 'GET_MP_SAVE_REQUEST',
    GET_MP_SAVE_SUCCESS: 'GET_MP_SAVE_SUCCESS',
    GET_MP_SAVE_FAILURE: 'GET_MP_SAVE_FAILURE',
    UPDATE_MP_SAVE_REQUEST: 'UPDATE_MP_SAVE_REQUEST',
    UPDATE_MP_SAVE_SUCCESS: 'UPDATE_MP_SAVE_SUCCESS',
    UPDATE_MP_SAVE_FAILURE: 'UPDATE_MP_SAVE_FAILURE'
};
