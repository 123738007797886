import { history } from '../helpers/history';
import { alertActions } from './alert.actions';
import { marketplaceConstants } from '../constants/marketplace.constants';
import { marketplaceService } from '../services/marketplace.service';

export const marketplaceActions = {
    addmarketplacesetting,
    getmarketplacesetting,
    updatemarketplacesetting
};

// Dispatch Add Lister Setting Action
function addmarketplacesetting(stateValue)
{
    return async dispatch => {
        dispatch(request({  }));
        marketplaceService.addmarketplacesetting(stateValue)
            .then(
                marketplacesetting => { 
                    if (marketplacesetting) {
                        if (marketplacesetting.status === "200") {
                            dispatch(success(marketplacesetting.data));
                            history.push('/marketplace');
                            dispatch(alertActions.success(marketplacesetting.message));
                        }
                        else
                        {
                            dispatch(failure(marketplacesetting.data));
                            history.push('/marketplace');
                            dispatch(alertActions.error(marketplacesetting.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(marketplacesetting.data));
                        history.push('/marketplace');
                        dispatch(alertActions.error(marketplacesetting.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('/marketplace');
                    dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(marketplacesetting) { return { type: marketplaceConstants.ADD_MP_SAVE_REQUEST, marketplacesetting } }
    function success(marketplacesetting) { return { type: marketplaceConstants.ADD_MP_SAVE_SUCCESS, marketplacesetting } }
    function failure(marketplacesetting) { return { type: marketplaceConstants.ADD_MP_SAVE_FAILURE, marketplacesetting } }
}

// Dispatch Get Lister Setting Action
function getmarketplacesetting()
{
    return dispatch => {
        dispatch(request({  }));
        marketplaceService.getmarketplacesetting()
            .then(
                marketplacesetting => { 
                    if (marketplacesetting) {
                        if (marketplacesetting.status === "200") {
                            dispatch(success(marketplacesetting.data));
                            history.push('marketplace');
                        }
                        else
                        {
                            dispatch(failure(marketplacesetting.data));
                            history.push('marketplace');
                        }
                    }
                    else
                    {
                        dispatch(failure(marketplacesetting.data));
                        history.push('marketplace');
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('marketplace');
                }
            );
    };

    function request(marketplacesetting) { return { type: marketplaceConstants.GET_MP_SAVE_REQUEST , marketplacesetting } }
    function success(marketplacesetting) { return { type: marketplaceConstants.GET_MP_SAVE_SUCCESS , marketplacesetting} }
    function failure(marketplacesetting) { return { type: marketplaceConstants.GET_MP_SAVE_FAILURE, marketplacesetting } }
}

// Dispatch Update Lister Setting Action
function updatemarketplacesetting(stateValue)
{
    return async dispatch => {
        dispatch(request({  }));
        marketplaceService.updatemarketplacesetting(stateValue)
            .then(
                marketplacesetting => { 
                    if (marketplacesetting) {
                        if (marketplacesetting.status === "200") {
                            dispatch(success(marketplacesetting.data));
                            history.push('/marketplace');
                            dispatch(alertActions.success(marketplacesetting.message));
                        }
                        else
                        {
                            dispatch(failure(marketplacesetting.data));
                            history.push('/marketplace');
                            dispatch(alertActions.error(marketplacesetting.message));
                        }
                    }
                    else
                    {
                        dispatch(failure(marketplacesetting.data));
                        history.push('/marketplace');
                        dispatch(alertActions.error(marketplacesetting.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('/marketplace');
                    dispatch(alertActions.error("Error"));
                }
            );
    };

    function request(marketplacesetting) { return { type: marketplaceConstants.UPDATE_MP_SAVE_REQUEST, marketplacesetting } }
    function success(marketplacesetting) { return { type: marketplaceConstants.UPDATE_MP_SAVE_SUCCESS, marketplacesetting } }
    function failure(marketplacesetting) { return { type: marketplaceConstants.UPDATE_MP_SAVE_FAILURE, marketplacesetting } }
}